#blank-page-main-title {
	margin-bottom:5px;
}

#blank-page-page-subtitle {
	display:block;
	margin-bottom:20px;
}

#content-loading {
	display: flex;
	height: calc(100vh - 278px);
	justify-content: center;
	align-items: center;
}