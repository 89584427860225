.color-nav {
    background-color: var(--green-five);
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.nav-link {
    color: white !important;
}

.nav-link:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

#logo {
    height: 65px;
    width: 130px;
    margin-left: 1rem;
}

.register-link {
    text-decoration: none !important;
}

.drop-custom-item {
    color: var(--green-five) !important;
    font-size: 14px;
    font-weight: bold !important;
    text-transform: capitalize;
}

.btn-default {
    font-weight: bold;
    font-family: helvetica;
    border: 0;
    padding: 13px 26px;
}

.transparent-btn {
    background-color: transparent;
}

.btn-default:focus {
    outline: none;
}

.add-button {
    background-color: transparent;
    padding: 4px 8px;
    border: none;
    color: var(--green-five);
    font-size: 14px;
    font-family: helvetica;
    font-weight: bold;
    outline: none;
    margin-bottom: 4px;
}

.add-button:focus {
    outline: none;
}

.inactive-btn {
    color: white;
}

.edit-btn {
    cursor: pointer;
    color: #003f79;
}

.delete-btn {
    color: red;
    cursor: pointer;
}

.view-btn {
    color: var(--green-five);
    cursor: pointer;
}

.generate-btn {
    color: var(--red-one);
    cursor: pointer;
}

.history-btn {
    color: var(--gray-dark);
    cursor: pointer;
}

.approve-btn {
    cursor: pointer;
    color: var(--green-three);
}

.reprove-btn {
    cursor: pointer;
    color: red;
}

.cancel-btn {
    cursor: pointer;
    color: red;
}

.dropdown-item {
    color: var(--green-five) !important;
    font-weight: bold !important;
    font-size: 16px;
    cursor: pointer;
}

.loader-container {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-container span {
    font-family: helvetica;
    font-weight: 600;
    color: var(--gray-medium);
}

.alert-message {
    font-family: helvetica;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.page-link {
    color: var(--green-five) !important;
}

.page-item.active .page-link {
    background-color: var(--green-one) !important;
    border-color: var(--green-one) !important;
}

.custom-legend {
    font-size: 16px;
    color: #212529;
    line-height: 24px;
    margin: 10px 20px;
    width: auto;
}

.custom-fieldset {
    border: 1px solid var(--border-fieldset);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 2rem;
}

.arrows-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin: 0 .2rem 0 .8rem;
    height: 300px;
}

.arrow-btn {
    color: var(--blue-three);
    cursor: pointer;
}

.arrow-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.empty-img {
    width: 300px;
    margin: 0 auto;
}

.empty-lbl {
    font-family: helvetica;
    font-size: 18px;
    color: var(--gray-dark);
}


.empty-model {
    font-size: 14px;
    color: var(--red-one);
}

.empty-info-array {
    font-size: 14px;
    color: var(--red-one);
}

