.card-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 40vw;
    height: 70vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.logo {
    margin: 0 auto;
}