.container {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: var(--white);
}

.container-fluid h3 {
	margin: 1.5rem 0 1.5rem 0;
}
.riquered-fieldset{
	width: 60%;
	margin: auto;
}
.row-custom {
	margin-bottom: 0.5rem;
}

em {
	color: red;
	font-size: 16px;
}

.button-position {
	display: flex;
	padding: 15px;
	gap: 20px;
	justify-content: flex-end;
}

/* input {
	display: block;
	padding: 0;
	margin: 0;
	border: 0;
	width: 100%;
	background-color: transparent;
} */

.td-row-additional {
	margin: 0;
	padding: 0;
}

.btns-fast-register {
	display: flex;
	padding: 8px;
	margin-bottom: 0.7rem;
	justify-content: space-between;
}

.form-row-fieldset {
	width: 100% !important;
}

.popup-field {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.wrapper-results {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 200px;
	gap: 1.5rem;
}

.table-custom {
	max-height: 150px;
	flex: 1;
	overflow: auto;
}

.option-cultivar {
	display: flex;
	align-items: flex-end;
}

.label-btn {
	display: flex;
	align-items: center;
	gap: 20px;
	width: 100%;
	padding: 5px;
}

.ag-cell-custom {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react-datepicker-wrapper [disabled] {
	background-color: #e9ecef;
}

.input-custom-to-date {
	display: block;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	cursor: pointer;
}

.checkbox-forms {
	width: 18%
}

.models-registers-type {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 80%;
}

.text-helper {
	font-size: smaller;
	color: var(--gray-dark);
}

.warning-pdfs {
	font-size: 12px;
	color: var(--red-one);
}

.label-with-warning label {
	width: 100%;
	margin-bottom: 0px;
}