:root {
  --blue-three: #21A0D2;
  --blue-four: #004A96;
  --green-one: #BADA9A;
  --green-two: #A1CE66;
  --green-three: #84BD4B;
  --green-four: #65AC1E;
  --green-five: #00793A;
  --white: #FFF;
  --gray-light: #F0F0F0;
  --gray-medium: #7C7C7C;
  --gray-dark: #333333;
  --border-fieldset: #ced4da;
  --red-one: #D0021B;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'helvetica', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 110px;
  background-color: var(--gray-light) !important;
}

h3 {
  color: var(--green-five);
  font-family: helvetica;
  font-size: 20px !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-check-input {
  height: 14px;
  width: 14px;
}