.myfunc-default-header {
    .ag-header-cell-label .ag-header-cell-text {
      white-space: normal !important;
     text-align: center;
    }
}

.react-datepicker__close-icon::after {
  background-color: green;
}

.row-invisible {
    visibility: hidden;
}
