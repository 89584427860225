.container-custom-search {
	width: 90vw;
	margin: auto;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.top-content{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-bottom:10px;
}

.arrow-back {
	cursor: pointer;
	color: var(--green-five);
	font-size: 25px;
}

.search-content {
	min-height: 75vh;
	padding: 10px;
	border-radius: 5px;
	background-color: #f7f7f7;
}

.filter-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 20px;
}

.search-filter-title {
	font-family: helvetica;
	font-weight: bold;
	font-size: 16px;
	color: var(--gray-medium);
}

.search-filter {
	padding: 5px 10px;
	border: 0.8px solid #ced4da;
	border-radius: 5px;
	margin: 0 1.2rem;
	font-family: helvetica;
	font-size: 16px;
	text-transform: capitalize;
	color: var(--gray-medium);
	cursor: pointer;
}

.form-check-custom {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	margin-left: 1rem;
}

.ag-header-cell-label {
	justify-content: center;
}

.ag-cell {
	display: flex;
	justify-content: center;
}

.loading-container {
	display: flex;
	justify-content: center;
	margin: auto;
}

.div-empty {
	width: 100%;
	display: flex;
	justify-content: center;
}

.check-in-search {
	top: 37px;
}

.react-simple-input-clear {
	position: absolute;
	right: 15px;
	top: 50%;
	line-height: 20px;
	margin-top: 5px;
	cursor: pointer;
}