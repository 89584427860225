.background-custom {
    background-color: white;
    position: relative;
    overflow: hidden;
    margin-top: -25px;
    height: 89vh;
}

.container-home {
    width: 100% !important;
    height: 100% !important;
    margin-top: -10px;
    padding: 0 !important;
    border-radius: 0!important;
    background-color: white !important;
    overflow: hidden;
    position: relative;
    box-shadow: none !important;
}

.container-load {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    padding-right: 10px !important;
    padding: 0 !important;
    border-radius: 0!important;
    background-color: white !important;
    box-shadow: none !important;
}

.container-home p {
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--green-five);
    margin-top: 2rem;
    text-align: left;
}

.container-home img {
    width: 98%;
    height: 95%;
    opacity: 0.8;
    object-fit: contain;
}