.custom-fieldset{
    padding: 15px !important;
    .ag-theme-alpine{
        margin: 0px;
        padding: 0px;
    }
    .ag-header ag-focus-managed ag-pivot-off{
        height: 70px;
        min-height: auto;
    }
}
.lpma {
    .form-group{
        padding-top: 10px;
    }
}
