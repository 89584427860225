.container {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: var(--white);
}

.container-fluid h3 {
	margin: 1.5rem 0 1.5rem 0;
}
.riquered-fieldset{
	width: 60%;
	margin: auto;
}
.row-custom {
	margin-bottom: 0.5rem;
}

.loading-page {
display: flex;
justify-content: center;
}

.error-field {
	color: #dc3545;
	font-size: 80%;
	width: 100%;
	margin-top: .25rem;
}