.solicitacao-edit
{
    .MuiStepLabel-alternativeLabel.MuiStepLabel-label {
        white-space: pre-wrap;
        margin: 20px -50px 0px -20px;
        width: auto !important;        
    }
    .MuiStepper-root{
        padding-top: 20px;
        padding-bottom: 30px;
        
    }
    .StepItemNTxt:first-child{    
        span {
            text-align: right;      
        }
    }
    .custom-fieldset {
        padding-left: 20px !important;    
        padding-right: 20px !important;    
    }
    .button-position{
        margin-top: -50px;
        &.addpaddingTop{
            margin-top: 20px;
        }
    }
    .tab-buttons-local{
        button{
            padding: 10px 20px 10px 20px;
            margin: 0px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border: 1.5px solid #dae1e8 !important;
            font-weight: 300;
            &.active{
                background-color: #e2e8e8 ;
                font-weight: bold;
            }
        }
    }
    .container{
        .control{
            background-color: white;
            .singleValue{
                color: #212529;
            }
        }
    }

    .ViewDatePickerDateFuture{
        background-color: #e5e5e5;
        color: #0c1a0d;
    }

    .ag-theme-alpine .ag-header{
        height: 50px !important;
        min-height: auto !important;        
    }
    .ag-theme-alpine .ag-header-row
    {
        height: 100% !important;
    }
    
    .span_anexo_filename{
        font-weight: bold;
        font-style: italic;
        font-size: 11pt;
    }
    
}

.search-content-filters {
    padding-right: 15px !important;
    padding-left: 10px !important;

    &.mod1{
        padding-left: 25px !important;
    }
}
