.custum_dateFiled{
	z-index: 99999;
    display: inline;
}
.custum-dateFiled-form-group{
    display: inline;
    .react-datepicker-wrapper{
        display: inline;
    }
    .react-datepicker-popper{
        z-index: 50;
    }
}
.custum_FileUploadMain{
    .custum_FileUpload{
        z-index: 0;        
    }
    .previewIcon{
        float: right;
    }
}

.custum_FilesUpload{
    padding: 0px 50px 0px, 10px;
    .button-position{
        padding-right: 5px;
        padding-left: 5px;
    }
    .btn-sm{
        width: 100% !important;
    }
    .custom-file-input{
        z-index: 1;
    }
    .custom-file-label{
        z-index: 0;
    }
    .custom-file{
        display: none;
    }
    .small{
        .small-bold{
            
        }
    }
    .fi-upload-td{
        padding: 10px 0px 0px 0px;
    }
}
.custom-gridview{
	padding: 0px;
	.ag-theme-alpine{
		margin: 0px;
		padding: 0px;
	}
	.ag-header ag-focus-managed ag-pivot-off{
		height: 70px;
		min-height: auto;
	}
}
.button-position {
    display: flex;
    padding: 15px;
    gap: 20px;
    justify-content: flex-end;
}

.tab-control{
    
    a.nav-link{
        color: #181d1f !important;
        z-index: 1;
    }

    .btn-add-newtab{
        z-index: 1000;
    }
    .tabItem{
        border: 1px solid #dee2e6;
        padding: 10px;
        .btn-remove-class{
            background-color: transparent !important;
            border: 1.5px solid transparent !important;
            color: rgb(101, 101, 101);
            padding: 5px 5px 0px 5px !important;
            margin-left: 20px;
        }
    }
    
    .tab-content{
        margin-top: -17px;
    }
    .tab-control-tabs{
        .tabItem-new-tab{
            background-color: rgba(0, 121, 58, 0.6) !important;
            border-width: 2px;
            color: beige !important;
            font-weight: bold;
            font-size: 14pt;
            padding: 10px 10px 0px 10px;
        }
    }
    
}
.grid-dropdown-edit{
    font-weight: bold;
}
.margin-bottom{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
    display: inline !important;
}
.form-control{
    &.disableMode{
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        padding-top: 0px !important;
        display: inline !important;
    }
}
.text-align-right{
    text-align: right;
}
.customGrid{
    .btn-success{
        padding: 4px 8px 4px 8px;
    }
}

.dataHide{
    display: none !important;
}
.richText{
    .public-DraftEditor-content{
        min-height: 100px;
        max-height: 300px;
    }   
    .disabled-color{
        background-color: #e9ecef;
    }
    .editor{
        text-align: justify;
        text-justify: inter-word;
        font-family: arial;
        font-size: 16px;
        color:#383e32;
    }
    div{
        z-index: inherit;
        span{
            z-index: inherit;
            select{
                z-index: 1 !important;
            }
            span{
                z-index: 0 !important;
            }
        }
    }
}
.margin-less{
    margin: 0px;
}

.CheckBoxMain {
    display: block;
    position: relative;
    padding-left: 45px;
    margin: 0px !important;
    cursor: pointer;
    /* Hide the default checkbox */
    input[type=checkbox] {
        visibility: hidden;
    }   
    label{
        padding-left: 25px;
    }
    /* Creating a custom checkbox
    based on demand */
    .geekmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: black;
    }

    /* Checkmark to be shown in checkbox */
    /* It is not be shown when not checked */
    .geekmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .geekmark {
        background-color: rgb(129, 133, 129);
    } 
    input:disabled ~ .geekmark {
        background-color: rgb(138, 138, 138);
    } 
    input:checked ~ .geekmark:after {
        display: block;
    }
    // input:active ~ .geekmark {
    //     background-color: red;
    // }
    input ~ .geekmark {
        background-color: rgb(253, 253, 253);
        border: gray 1px solid;
    }
    
    .geekmark:after {
        left: 6px;
        bottom: 3px;
        width: 4px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
  
}

.RadioBoxMain {
    display: block;
    position: relative;
    padding-left: 45px;
    margin: 0px !important;
    cursor: pointer;
    /* Hide the default checkbox */
    input[type=radio] {
        visibility: hidden;
    }
    
    label{
        padding-left: 25px;
    }
    /* Creating a custom checkbox
    based on demand */
    .geekmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: black;
    }

    /* Checkmark to be shown in checkbox */
    /* It is not be shown when not checked */
    .geekmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .geekmark {
        background-color: rgb(129, 133, 129);
    } 
    input:disabled ~ .geekmark {
        background-color: rgb(138, 138, 138);
    } 
    input:checked ~ .geekmark:after {
        display: block;
    }
    // input:active ~ .geekmark {
    //     background-color: red;
    // }
    input ~ .geekmark {
        background-color: rgb(253, 253, 253);
        border: gray 1px solid;
        border-radius: 20px;
    }
    
    .geekmark:after {
        left: 6px;
        bottom: 3px;
        width: 4px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
  
}


.custom-edit-field{
    .c-fieldset{
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0px !important;
    }
    .custom-legend-buttons{
        margin: 0px;
        padding: 0px;
        align-self: flex-end;
        button{
            padding: 6px 12px;
        }
    }
    .custom-edit-field-rightButton{
        padding: 0px;
        margin: -20px -10px 0px 0px;
        height: 32px;
    }
}

.dialogEditBox{
    // @media (min-width: 576px)
    .modal-dialog{
        max-width: 80% !important;
    }
    &.modal-dialog-50{
        .modal-dialog{
            max-width: 50% !important;
        }
    }
    &.modal-dialog-25{
        .modal-dialog{
            max-width: 25% !important;
        }
    }
    &.positionTopAlign{
        margin: 0px 0px -20px 0px !important;
    }
    .singleTitle{
        font-size: 12pt;
    }
}

.dialogEditButton{    
    &.positionTopAlign{
        margin: 0px 0px -25px 0px !important;
        z-index: 1;
    }
}
.dropDown-select-input{
    .dropDown-select-input-element__menu
    {
        z-index: 100 !important;
    }
    
}
.custom-action-field{

}
.custom-action-field-button{
    padding-top: 30px;
    padding-bottom: 0px;    
    button{

    }
}
.SearchBtnDialog{
    .search-dialog-content{
        min-height: 50vh !important;
        padding: 10px;
        border-radius: 5px;
        background-color: #f7f7f7;
    }
    .modal-dialog{
        max-width: 80% !important;
    }
    .form-group{
        margin-bottom: 10px !important;
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .checksContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-top: 5px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checksContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border: 1px solid;
        border-color: #babfc7
      }
      
      /* On mouse-over, add a grey background color */
      .checksContainer:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .checksContainer input:checked ~ .checkmark {
            background-color: #727272;
            border-radius: 20px;
            border: 0px;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .checksContainer input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .checksContainer .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
}
.textField-input-clear{
    position    : absolute;
	right       : 20px;
	top         : 50%;
	line-height : 20px;
	margin-top  : 5px;
	cursor      : pointer;
    color       : #cccccc;
    font-weight : 800;
    font-size   : 20px;
}

.react-simple-input-clear-opc2 {
	position: absolute;
	top: 50%;
	line-height: 20px;
	margin-top: 5px;
	cursor: pointer;
}
.posText{
    font-size: 11pt;
    font-style: italic;
    color:#383e32;
}

